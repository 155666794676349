import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

const HeaderWrapper = styled.header`
  width: 100%;
  padding: var(--space-l) 0 12rem;
  text-align: right;
  opacity: ${props => (props.inView ? '1' : '0')};
  transition: all 400ms var(--easeInOutCubic);
  transition-delay: 200ms;

  @media (min-width: 850px) {
    padding: 5.6rem 0 17.4rem;
  }

  a {
    text-decoration: none;

    @media (min-width: 600px) {
      text-decoration: underline;
    }
  }
`;

const Header = () => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const data = useStaticQuery(graphql`
    query {
      allPrismicLayout {
        edges {
          node {
            data {
              link_text
              link_url {
                url
              }
            }
          }
        }
      }
    }
  `);

  const headerData = data.allPrismicLayout.edges[0].node.data;

  return (
    <HeaderWrapper ref={ref} inView={inView}>
      <a href={headerData.link_url.url} target="_blank" rel="noreferrer">
        {headerData.link_text}
      </a>
    </HeaderWrapper>
  );
};

export default Header;
