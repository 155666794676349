import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  position: relative;
  width: 100%;
  max-width: var(--width-max);
  margin: 0 auto;
  height: 42rem;
  padding: var(--space-l);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (min-width: 850px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: var(--space-xxxl) var(--space-xl) calc(var(--space-xxxl) - 2rem);
    height: 75rem;
  }

  &:after {
    content: '';
    background-color: var(--c-green);
    position: absolute;
    width: 100vw;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    z-index: -1;
  }

  a {
    color: var(--c-white);
    font-size: 2rem;
    font-weight: var(--f-light);
    margin-bottom: var(--space-xs);

    @media (min-width: 850px) {
      font-size: 3.2rem;
    }

    &:hover {
      opacity: 0.7;
      color: var(--c-white);
    }
  }
`;

const Copyright = styled.p`
  color: var(--c-white);
  font-weight: var(--f-light);

  @media (min-width: 850px) {
    font-size: 1.8rem;
  }
`;

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicLayout {
        edges {
          node {
            data {
              link_text
              link_url {
                url
              }
            }
          }
        }
      }
    }
  `);

  const footerData = data.allPrismicLayout.edges[0].node.data;

  return (
    <FooterWrapper>
      <a href={footerData.link_url.url} target="_blank" rel="noreferrer">
        {footerData.link_text}
      </a>

      <Copyright>
        © IOTO™ {new Date().getFullYear()}. All Rights Reserved.
      </Copyright>
    </FooterWrapper>
  );
};

export default Footer;
