import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import Header from './header';
import Footer from './footer';

const GlobalStyle = createGlobalStyle`
  ${reset}

  :root {
    font-size: 62.5%;

    --c-black: #121212;
    --c-white: #ffffff;
    --c-green: #193842;

    --f-stack: 'Roboto', Helvetica, Arial, sans-serif;
    --f-light: 300;
    --f-regular: 400;

    --width-max: 124rem;

    --spacing: .8rem;
    --space-xxxs: calc(var(--spacing) * 0.5);
    --space-xxs: calc(var(--spacing) * 1);
    --space-xs: calc(var(--spacing) * 1.5);
    --space-s: calc(var(--spacing) * 2);
    --space-m: calc(var(--spacing) * 3);
    --space-l: calc(var(--spacing) * 4);
    --space-xl: calc(var(--spacing) * 5);
    --space-xxl: calc(var(--spacing) * 9);
    --space-xxxl: calc(var(--spacing) * 18);

    --easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
    --ease-200: all 200ms var(--easeInOutCubic);
  }

  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html,
  body {
    min-height: 100%;
    width: 100%;
    margin: 0;
  }

  body {
    font-family: var(--f-stack);
    color: #111111;
    background-color: #ffffff;
    font-size: 1.4rem;
    font-weight: var(--f-regular);
    line-height: 2.0rem;
    letter-spacing: .02rem;
    text-align: left;
    font-variant-numeric: lining-nums;
    font-feature-settings: 'lnum';
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: var(--c-black);
    transition: var(--ease-200);
    text-decoration: none;

    &:hover {
      color: var(--c-green);
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: normal;
  }
`;

const Wrapper = styled.main`
  width: 100%;
  max-width: var(--width-max);
  margin: 0 auto;
  padding: 0 var(--space-l);

  @media (min-width: 850px) {
    padding: 0 var(--space-xl);
  }
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <Header siteTitle={data.site.siteMetadata?.title || `IOTO™`} />

        <main>{children}</main>
      </Wrapper>
      <Footer />
    </>
  );
};

export default Layout;
